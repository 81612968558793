<template>
  <div class="footer__inner">
    <div class="footer__container">
      <router-link to="/" class="footer__logo">
        <img src="@/assets/images/logo.svg" width="71" height="71" alt="logo" />
      </router-link>
      <div class="footer__content">
        <div class="footer__menu">
          <router-link to="/" class="footer__menu--home">Головна</router-link>
          <ul class="footer__menu--list menu--list" v-if="menu.length > 0">
            <li class="menu--list__item" v-for="item in menu" :key="item.id">
              <router-link class="menu--list__link" :to="item.url">{{
                getTranslatedTitle(item.translations, $i18n.locale)
              }}</router-link>
            </li>
          </ul>
          <lang-switcher></lang-switcher>
        </div>
        <div class="footer__info">
          <p class="footer__info--name">Компанія «iIT Trading»</p>
          <div class="footer__search">
            <button
              class="footer__search--btn"
              @click="$emit('showFormSearch')"
            >
              search
            </button>
          </div>
          <div class="footer__social">
            <a
              class="footer__social--link fb"
              :href="footer.facebook"
              target="_blank"
              >facebook</a
            >
            <a
              class="footer__social--link in"
              :href="footer.linkedin"
              target="_blank"
              >linkedin</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="footer__ipad">
      <address class="footer__info--address" v-if="translations[$i18n.locale]">
        {{ getField("company_position", $i18n.locale) }}
      </address>
      <div class="footer__call">
        <div class="footer__call--number">
          <a :href="'tel:' + footer.tel">
            <span>+38 (044) 33-999-77</span>
            <span>Зателефонувати</span>
          </a>
          <span>Київ</span>
        </div>
      </div>
      <a href="" class="footer__info--map">мапа проїзду</a>
    </div>

    <div class="footer__copy">
      <div class="footer__copy--left" v-if="translations[$i18n.locale]">
        {{ getField("rights_reserved", $i18n.locale) }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const LangSwitcher = defineAsyncComponent(() =>
  import("@/components/UI/LangSwitcher")
);
// import VLazyImage from "v-lazy-image";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    LangSwitcher,
    // VLazyImage,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    footer: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTranslations(locale) {
      return this.translations[locale];
    },
    getField(fieldName, locale) {
      return this.getTranslations(locale)[fieldName];
    },
    getTranslatedTitle(transArray, locale) {
      let title = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          title = element.title;
        }
      });
      return title;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.footer {
  &__container {
    max-width: 1396px;
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    grid-template-columns: 71px 1fr;
    gap: 24px 32px;

    &--wrap {
      display: grid;
      grid-template-columns: 66px 1fr max-content;
      align-items: center;
      grid-gap: 15px 20px;
      margin-bottom: 25px;
    }

    &--inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__logo {
    display: block;
    align-self: flex-start;

    img {
      display: block;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--home {
      flex: 0 0 auto;
      width: 17px;
      height: 16px;
      font-size: 0;
      background: #f9b80e;
      mask-image: url("~@/assets/images/home.svg");
      mask-size: 100% 100%;
      mask-repeat: no-repeat;

      @media (hover: hover) {
        &:hover {
          background: #fff;
        }
      }
    }
  }

  &__search {
    display: flex;
    padding: 8px 0;
    @include property("padding-right", 30, 15, true, 1366, 1024);
    @include property("padding-left", 30, 15, true, 1366, 1024);

    @media (hover: hover) {
      &:hover {
        .footer__search--btn {
          &::before {
            width: 100%;
            opacity: 1;
            visibility: visible;
          }
        }

        .search--hide {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
    }

    &--btn {
      position: relative;
      font-size: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/search.svg") no-repeat;

      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #f9b80e;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
      }
    }
  }

  &__call {
    min-width: max-content;
    display: flex;
    justify-content: flex-end;

    &--number {
      display: flex;
      align-items: center;

      a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-right: 10px;
        line-height: calc(26 / 20);
        text-decoration: none !important;

        &::before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          flex: 0 0 auto;
          margin-right: 15px;
          background: url("~@/assets/images/phone-icon.svg") no-repeat;
          background-size: 100% 100%;
        }

        @media (hover: hover) {
          &:hover {
            span {
              &:nth-of-type(1) {
                opacity: 0;
                visibility: hidden;
              }

              &:nth-of-type(2) {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        span {
          transition: all 0.3s linear;
          &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 31px;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            color: #f9b80e;
          }
        }
      }

      > span {
        font-size: 20px;
        line-height: calc(26 / 20);
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin: 0;

    &--link {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-color: #f9b80e;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;

      &:not(:last-child) {
        margin-right: 18px;
      }

      &.fb {
        mask-image: url("~@/assets/images/fb.svg");
      }

      &.in {
        mask-image: url("~@/assets/images/in.svg");
      }

      @media (hover: hover) {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &--name {
      margin: 0;
      font-size: 1rem;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      color: #e1e1e1;
      text-transform: uppercase;
      margin-right: auto !important;
    }

    &--address {
      position: relative;
      display: flex;
      font-size: 1rem;
      line-height: calc(30 / 16);
      color: #e1e1e1;
      font-style: normal;

      &::before {
        content: "";
        display: block;
        width: 12px;
        height: 16px;
        flex: 0 0 auto;
        background: url("~@/assets/images/address.svg") no-repeat;
        background-size: 100% 100%;
        margin-top: 7px;
        margin-right: 8px;
      }
    }

    &--map {
      text-decoration: underline;
      font-size: 1rem;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      color: #f9b80e;
      margin-left: 20px;

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  &__copy {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px 20px;
    max-width: 1396px;
    padding: 0 15px;
    margin: 0 auto;
    font-size: 14px;
    line-height: calc(30 / 14);
    color: #afafaf;
    @include property("margin-top", 45, 20, true, 1366, 768);

    @media (min-width: 1024px) {
      justify-content: space-between;
      grid-template-columns: max-content max-content;
    }

    &--logo {
      position: relative;
      top: 4px;
      display: inline-flex;
    }
  }

  &__ipad {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0 20px;
    padding: 0 15px;
    @include property("margin-top", 45, 20, true, 1366, 768);
  }

  &__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 20px;
  }
}

.menu--list {
  display: flex;
  width: 100%;
  margin: 0 35px;
  flex-wrap: wrap;
  align-items: center;
  @include property("margin-left", 38, 15, true, 1670, 1366);

  @media (max-width: 1023.98px) {
    margin: 0 20px;
  }

  @media (min-width: 915px) and (max-width: 1279.98px) {
    justify-content: space-between;
  }

  &__item {
    &:not(:last-child) {
      @include property("margin-right", 27, 15, true, 1670, 1366);
    }

    @media (max-width: 1023.98px) {
      padding: 5px 0;
    }
  }

  &__link {
    font-size: 1rem;
    line-height: calc(20 / 16);
    color: #f9b80e;
    text-decoration: none !important;

    @media (hover: hover) {
      &:hover {
        color: #fff;
      }
    }
  }
}

.search--form {
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    position: relative;
    max-width: 812px;
    width: 100%;

    input {
      font-size: 18px;
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      padding: 7px 0;
      width: 100%;
      border-bottom: 0.5px solid #e1e1e1;

      &:focus,
      &:valid {
        ~ label {
          top: -5px;
        }
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
      font-size: 18px;
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      transition: all 0.3s linear;
      cursor: text;
    }
  }

  button {
    padding: 4px 17px 5px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-left: 20px;
    transition: all 0.3s linear;

    @media (hover: hover) {
      &:hover {
        color: #f9b80e;
        border-color: #f9b80e;
      }
    }
  }
}
</style>
